
import React from 'react'
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';



function ExpansionSummary() {


  return (

    <div className="ExpansionSummaryContainer">
      <h1>Expansion summary</h1>
      <div className="ExpansionSummaryBody WidgetsBody">
        <li><FiberManualRecordRoundedIcon /> 2800 Sq Ft space for prayers and other facilities</li>
        <li><FiberManualRecordRoundedIcon /> Separate congregation prayer spaces for 300+ brothers and sisters</li>
        <li><FiberManualRecordRoundedIcon /> Ta’alimul Quran, Hadith and Islamic educational programs for all ages</li>
        <li><FiberManualRecordRoundedIcon /> Spiritual and communal activities throughout the year</li>
        <li><FiberManualRecordRoundedIcon /> Islamic library of Al-Quran, hadith and Islamic books in different languages</li>
        <li><FiberManualRecordRoundedIcon /> Space for children for spiritual and physical developments</li>
        <li><FiberManualRecordRoundedIcon /> Help with funeral and graveyard services </li>
        <li><FiberManualRecordRoundedIcon /> Distribute Quran & Islamic books among Muslims and Non-Muslims</li>
        <li><FiberManualRecordRoundedIcon /> Hold monthly family and community events</li>
        <li><FiberManualRecordRoundedIcon /> Space for Nikah ceremonies</li>
        <li><FiberManualRecordRoundedIcon /> Eid-ul-Fitr and Eid-ul-Adha prayers in collaboration with local communities</li>
        <li><FiberManualRecordRoundedIcon /> Advice and counseling activities supported by the Imam and Khateeb</li>
      </div>
    </div>

  );
}










export default ExpansionSummary