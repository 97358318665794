
import React from 'react'
import MasjidLogo from '../assets/imgs/MasjidBaitusSalam_Logo.png';




function ContactUsDetails() {


  return (

    <div className="ExpansionSummaryContainer">
      <h1>CONTACT US FOR DETAILS</h1>
      <div className="ContactUsDetailsWgtBody WidgetsBody">

        <li>
          <div className="listImgCol"><img className="listImg" src={MasjidLogo} /></div>
          <div className="ContactUsDetaiInfo">
            <h2>Mohammad Nurul Anwar</h2>
            <p>President</p>
            <p><a href="tel: +1 (718) 306-7288">+1 (718) 306-7288</a></p> 
          </div>
        </li>

        <li>
          <div className="listImgCol"><img className="listImg" src={MasjidLogo} /></div>
          <div className="ContactUsDetaiInfo">
            <h2>Shafiul Azam</h2>
            <p>Secretary</p>
            <p><a href="tel: +1 (347) 324-4766">+1 (347) 324-4766</a></p> 
          </div>
        </li>

        

        <li>
          <div className="listImgCol"><img className="listImg" src={MasjidLogo} /></div>
          <div className="ContactUsDetaiInfo">
            <h2>Anisur Rahman</h2>
            <p>Assistant Secretary</p>
            <p><a href="tel: +1 (347) 845-1020">+1 (347) 845-1020</a></p> 
          </div>
        </li>

        <li>
          <div className="listImgCol"><img className="listImg" src={MasjidLogo} /></div>
          <div className="ContactUsDetaiInfo">
            <h2>Emdad Ullah</h2>
            <p>Assistant Secretary</p>
            
            <p><a href="tel: +1 (646) 207-3815">+1 (646) 207-3815</a></p>
          </div>
        </li>

        <li>
          <div className="listImgCol"><img className="listImg" src={MasjidLogo} /></div>
          <div className="ContactUsDetaiInfo">
            <h2>Abdul Awal</h2>
            <p>Treasurer</p>
            <p><a href="tel: +1 (646) 920-8144">+1 (646) 920-8144</a></p> 
          </div>
        </li>



      </div>
    </div>

  );
}










export default ContactUsDetails