
import React from 'react'
import Grid from '@material-ui/core/Grid';
import HandIcon from '../assets/imgs/HandIcon.svg'
import Button from '@material-ui/core/Button';

import masjidImg from '../assets/imgs/DEENCONNECT_MasjidBaitusSalam_Jummah_Azan.jpg'


function Banner() {
  return (
    <div className="ExpansionBannerContainer">

      <div className="ExpansionContainer ExpansionHeaderInner">
        <div className="BannerSmallText">URGENT APPEAL</div>
        <h1 className="ExpansionSectionTitle">New Masjid in East Flatbush, Brooklyn, NY with 2800 SQ FT and need your URGENT support.</h1>

        <div className="ExpansionBannerGrid">
        <Grid container spacing={3}>

          <Grid item md={12} xs={12}>
            <div className="BannerVidoeContainer">
              <img className="bannerImgTop" src={masjidImg} />
              {/* <iframe src="https://www.youtube.com/embed/q5RGTX5FOK8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              </div>
          </Grid>

          {/* <Grid item md={4} xs={12}>
            <div className="BannerwgtContainer">
                <div className="BannerwgtTopSection"><img className="handIcon" src={HandIcon} /></div>
                <div className="BannerwgtBodySection">
                  <h1>Support us with your generous donations</h1>
                  <Button className="ExpansionFillButton" variant="contained" href="#contained-buttons"> Donate now</Button>
                </div>
            </div>
          </Grid> */}

        </Grid>
        </div>

      </div>
    </div>
  );
}










export default Banner