import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import HomePage from "./components/HomePage"

function App() {
  return (
    <Router>
      <HomePage />
    </Router>
  );
}
export default App;
