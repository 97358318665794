
import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PROPIMG from '../assets/imgs/DEENCONNECT_MasjidBaitusSalam_5.jpeg'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import HandIcon from '../assets/imgs/HandIconGreen.svg'
import ExpansionSummary from './ExpansionSummary'
import ContactUsDetails from './ContactUsDetails'




const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});


function TabsSection() {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <>
      <div className="ExpansionTabBar">
        <div className="ExpansionContainer">

          <div className="dontaineButnWhite ShowMobile">
            <div className="ExpansionHeaderDontainerButtonContainer">
              <Button className="ExpansionFillButton" variant="contained" href="https://give.deenfund.com/baitus-salam">
                <img className="ExpansionFillButtonIcon" src={HandIcon} />Donate now</Button>
            </div>
          </div>

          <Grid container spacing={3}>

            <Grid item md={8} sm={8} xs={12}>
              <Tabs
                value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered >
                <Tab label="Description" href="#Description"/>
                <Tab label="Project Phase" href="#Project-Phase"/>
                <Tab label="Updates" href="#Updates"/>
                {/* <Tab label="Guests" href="#Guests"/>
                <Tab label="Testimonials" href="#Testimonials"/> */}
                <Tab label="Community" href="#Community"/>
              </Tabs>
            </Grid>

            <Grid item md={4} sm={4} xs={12} className="hideMobile">
              <div className="dontaineButnWhite">
                <div className="ExpansionHeaderDontainerButtonContainer">
                  <Button className="ExpansionFillButton" variant="contained" href="https://give.deenfund.com/baitus-salam">
                    <img className="ExpansionFillButtonIcon" src={HandIcon} />Donate now</Button>
                </div>
              </div>
            </Grid>

          </Grid>

          

        </div>
      </div>

      <div className="ExpansionContainer">

        <div id="Description" className="ExpansionTabsContainer">
          <Grid container spacing={3}>
            <Grid item md={8} xs={12}>
              <div className="TabsContainerPannel">
                <h1>New Masjid in East Flatbush, Brooklyn, NY with 2800 SQ FT.  85% construction is done and need your generous support.</h1>
                <p>The Muslim population in the East Flatbush area has been growing past 10-12 years and the need of an Islamic Center/Masjid is much anticipated among the Muslims and families. The Muslims in the community started praying in the basement of a private house offered by a local generous family.  As the Muslim community continues to grow in East Flatbush, local Muslims have taken the initiative to purchase a house back in September 2019 and build a masjid - Masjid Baitus Salam/MUNA Center of East Flatbush. Masjid Baitus Salam will have 2 floors and accommodations for both brothers and sisters. The construction started from March 2020 and now in need of urgent funds for full completion.</p>
                <div className="TabsContainerQoute">“Whoever builds a mosque for the sake of Allah, then Allah will build for him a house like it in Paradise. (Sahih al-Bukhari, Sahih Muslim)”</div>
                <div className="propertyImg"><img src={PROPIMG} /></div>
                <p>Masjid Baitus Salam will provide various services to the community including daily 5 prayers and Salat Al-Jummah with accommodation of over 300 prayer spaces, Ta’alimul Quran programs for children and adults/elders, and Islamic teaching programs for everyone. For Non-Muslims, it will serve as the go-to center to know more about Islam and Muslims. For children, there will be a dedicated space to study, play and do physical activities.	</p>
                <p>Masjid Baitus Salam initiated to build the project back in March 2020 and would cost $700K to complete.  For our own benefit in here and here-after, it’s encouraged that we all support this great cause which will accommodate over 300 prayer spaces, inshaAllah.  Masjid Baitus Salam wants to use the 2800 square feet for prayer spaces and 5 car parking slots for visitors, and including more activities and engaging in the community. Building library, adding more events to include families and children, and facilitating Nikah events and much more will be provided.</p>
                {/* <Link className="TextReadMore">READ MORE <KeyboardArrowDownRoundedIcon /></Link>  */}
              </div >
            </Grid>

            <Grid item md={4} xs={12}>
              <ExpansionSummary />
              <ContactUsDetails />
             
            </Grid>


          </Grid>
        </div>
      </div>


    </>


  );
}










export default TabsSection