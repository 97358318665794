
import React from 'react'
import Grid from '@material-ui/core/Grid';
import profPhoto from '../assets/imgs/profilePhoto.jfif'
import BICNYLogo from '../assets/imgs/BICNY_Logo.png'
import NaharLogo from '../assets/imgs/NaharLogo.png'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import Button from '@material-ui/core/Button';
import HandIcon from '../assets/imgs/HandIcon.svg'



function OurCommunitySupport() {


  return (

    <div className="ExpansionOurCommunitySupportContainer">

      <div id="Community" className="ExpansionContainer">
        <h1 className="ExpansionSectionTitle">Our Community support</h1>

        <div className="ExpansionCardContainer">
         
              <div className="guestsCardContainer">

              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                <img src={NaharLogo} /> 
                </div>
                </div>
                <h2>North American Humanitarian Aid & Relief (NAHAR)</h2>
              </div>
           

            
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                <img src={BICNYLogo} /> 
                </div>
                </div>
                <h2>Brooklyn Islamic Center <br/>Brooklyn, NY</h2> 
              </div>

              </div>
            

            {/* <Grid item md={2} sm={6} xs={12}>
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                  <img src="https://www.northmiamifl.gov/ImageRepository/Document?documentID=92" /> 
                </div>
                <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>Islamic Schools Association of New York</h2>
              </div>
            </Grid>

            <Grid item md={2} sm={6} xs={12}>
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                </div>
                <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>Islamic Schools Association of New York</h2>
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                </div>
                <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>Islamic Schools Association of New York</h2>
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                </div>
                </div>
                <h2>Islamic Schools Association of New York</h2>
              </div>
            </Grid>
            <Grid item md={2} sm={6} xs={12}>
              <div className="guestsCard">
              <div className="GuestPhotoContainer">
                <div className="GuestPhoto">
                  <img src={profPhoto} /> 
                </div>
                <Button variant="contained" className="guestsCardPlayButton" href="#contained-buttons"><PlayArrowRoundedIcon /></Button>
                </div>
                <h2>Islamic Schools Association of New York</h2>
              </div>
            </Grid> */}
               <Grid container spacing={3}>
            <Grid item xs={12}>
            <div className="ButtonCenter groupButton">
              <Button className="ExpansionFillButton" variant="contained" href="https://give.deenfund.com/baitus-salam">
              <img className="ExpansionFillButtonIcon" src={HandIcon} /> Support us now</Button>
            </div>
            </Grid>

          </Grid>
        </div>
      </div>


    </div>



  );
}


export default OurCommunitySupport